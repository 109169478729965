
.logininput2{
    position: relative;
}
.icon2{
    position: absolute;
    right: 20px !important;
    top: 5px;
    cursor: pointer;
}

.card_main_con{
    width: 50%;
}
@media (max-width:700px){
    .card_main_con{
        width: 95%;
    } 
}

