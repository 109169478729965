.search_box{
    width: 20%;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
   
}

.qr-code-container {
    display: inline-block; /* Display QR codes horizontally */
    margin: 30px; /* Adjust the margin to control the spacing */
  }