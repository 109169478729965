

.imagesuser{
    
    width: 64px !important;
     height:65px !important;
     border-radius: 100%;



}

.Card{
    width: 70%;
    background-color: #fbfffb;
    ;
}
.scanheading{
    font-size: 16px;
    font-weight: 400;
    color: black;
}
.scaned{
    font-size: 20px;
    font-weight: 500;
    color: black;
    padding-bottom: 15px;
    position: sticky;
    top: 0%;
    background-color: 
    #FFFFFF;
    border-radius: 10px;
}