.showQrCode_con {
  background-image: url("/public/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;

  .resource_card {
    width: fit-content;
    border-radius: 6px;
    padding: 5px;

    & small {
      font-size: 10px;
    }

    .large {
      font-size: 12px;
    }
  }

  .child_image_con {
    padding: 3%;

    & img {
      /* width: 100px; */
    }
  }

  .profileIcon {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .nameAge {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }

  .bottomBtn {
    padding-left: 40px;
    padding-top: 10px;
  }

  @media (max-width: 550px) {
    .detail_con {
      min-width: 80%;
    }
  }

  .playstore {
    background-color: rgb(255, 255, 255);
    border: 1px solid #ffffff;
    border-radius: 8px;
    background: #e2e8f8;
    padding: 10px 15px;
    width: 80%;
    margin: auto;
    margin-top: 4%;
  }
  .link {
    font-size: 13px;
    color: #0049d3;
    display: flex;

    align-items: center;
    align-items: center;
  }

  .message {
    padding-left: 36px;
  }

  .name-age {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }

  .tableClass {
    font-weight: 500 !important;
    padding-bottom: 8px;
  }

  .pra {
    font-size: 18px;
    padding: 10px 15px;
    text-align: justify;

    border-radius: 4px;
    display: flex;
    justify-content: center;
  }

  .table-main-div {
    background: rgba(255, 255, 255, 0.727);
    border: 2px solid white;

    border-radius: 4px;
  }

  .table-subClass {
    font-weight: 200;
    color: #262626;
  }

  .trDiv {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 479px) {
    .showQrCode_con {
      background-image: url("/public/images/bg.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .playstore {
      /* margin-left: 4%; */
      margin-top: 3%;
      width: 90% !important;
      background-color: rgb(255, 255, 255);
      border: 1px solid #ffffff;
      border-radius: 8px;
      background: #e2e8f8;
      padding: 10px 15px;
    }

    .pra {
      font-size: 12px;
      padding: 10px 15px;
      text-align: justify;

      border-radius: 4px;
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 991px) {
    .table-main-div {
      border: 0px solid white;
      display: flex;
      align-items: center;
    }

    .pra {
      font-size: 18px;
      padding: 10px 5px;
      text-align: justify;
      background: rgba(255, 255, 255, 0.905);
      width: 89%;
      border: none !important;
      border-radius: 0px !important;
      display: flex;
      justify-content: flex-start !important;
    }

    .tableClass {
      font-weight: 500 !important;
      font-size: 22px !important;
      line-height: 36px;
    }

    .playstore {
      margin-left: 3%;
      margin-top: 3%;
      width: 95% !important;
      background-color: rgb(255, 255, 255);
      border: 1px solid #ffffff;
      border-radius: 8px;
      background: #e2e8f8;
      padding: 10px 15px;
    }

    .showQrCode_con {
      background-image: url("/public/images/bg.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  @media (min-width: 992px) and (max-width: 1440px) {
    .pra {
      font-size: 18px;
      padding: 10px 5px;
      text-align: justify;
      background: rgba(255, 255, 255, 0.905);
      width: 89%;
      border: none !important;
      border-radius: 0px !important;
      display: flex;
      justify-content: flex-start !important;
    }

    .tableClass {
      font-weight: 500 !important;
      font-size: 24px !important;
      line-height: 36px;
    }

    .table-subClass {
      font-weight: 200;
      color: #262626;
      font-size: 22px;
    }

    .playstore {
      margin-left: 3%;
      margin-top: 3%;
      width: 95% !important;
      background-color: rgb(255, 255, 255);
      border: 1px solid #ffffff;
      border-radius: 8px;
      background: #e2e8f8;
      padding: 10px 15px;
    }

    .showQrCode_con {
      background-image: url("/public/images/bg.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.mainDiv {
  background: rgb(255, 255, 255);
  padding: 40px;
}

.btmain {
  width: 100%;
  display: flex;
  justify-content: end;
}
.btns {
  background: blue;
  padding: 7px 15px;
  border: none;
  color: white;
  cursor: pointer;
}

.icon {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  align-items: center;
}
